import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { getToken } from '../api/token';
import { OfferData, OfferResponse } from '../types/offerFace';
import { getOfferById } from '../api/offer';

const useOfferLogic = () => {
  const [selectedOffer, setSelectedOffer] = useState<OfferData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      const savedOffer = localStorage.getItem('selectedOffer');
      const userEmail = localStorage.getItem('userEmail');
      if (savedOffer && userEmail) {
        setSelectedOffer(JSON.parse(savedOffer));
        localStorage.removeItem('selectedOffer');
      }
    }
  }, [isLoggedIn]);

  const handleDetailsClick = async (dlc_id: string) => {
    try {
      const userEmail = localStorage.getItem('userEmail');
      const authToken = getToken();

      if (!authToken || !userEmail) {
        navigate('/loginpage', { state: { from: '/' } });
        return;
      }

      const response: OfferResponse = await getOfferById(dlc_id);
      const offer = response.payload;

      setSelectedOffer(offer);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(String(error));
      }
    }
  };

  const handleCloseDetails = () => {
    setSelectedOffer(null);
    localStorage.removeItem('selectedOffer');
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('userEmail');
      localStorage.removeItem('selectedOffer');
      setSelectedOffer(null);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return {
    selectedOffer,
    setSelectedOffer,
    handleDetailsClick,
    handleCloseDetails,
    error,
  };
};

export default useOfferLogic;