import { useState, useEffect } from 'react';
import { getOffers } from '../api/offers';
import { OfferData } from '../types/offerFace';
import useAuthLogic from './useAuthLogic'; // Import useAuthLogic

const NR_BEST_OFFERS = 4; // Set the number of best offers

const useOfferListLogic = () => {
  const [offers, setOffers] = useState<OfferData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { userEmail } = useAuthLogic(); // Get the user's email

  const fetchOffersData = async () => {
    try {
      const response = await getOffers();
      setOffers(response.payload);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(String(error));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOffersData();
  }, []);

  const filteredOffers = offers.filter((offer) => offer.ini_email !== userEmail); // Filter out user's own offers

  const borrowOffers = filteredOffers
    .filter((offer) => offer.ini_role === 'borrow')
    .map((offer) => ({
      ...offer,
    }))
    .sort((a, b) => b.interest - a.interest)
    .slice(0, NR_BEST_OFFERS);

  const lendOffers = filteredOffers
    .filter((offer) => offer.ini_role === 'lend')
    .map((offer) => ({
      ...offer,
    }))
    .sort((a, b) => a.interest - b.interest)
    .slice(0, NR_BEST_OFFERS);

  const refreshOffers = () => {
    setLoading(true);
    fetchOffersData();
  };

  return {
    borrowOffers,
    lendOffers,
    loading,
    error,
    refreshOffers, // Export refreshOffers instead of fetchOffersData
  };
};

export default useOfferListLogic;