import axios from 'axios';
import { API_URL } from '../config';
import { getToken } from './token'; // Import the getToken function from AuthContext
import { AcceptOfferParams } from '../types/offerFace';

export const acceptOffer = async (params: AcceptOfferParams) => {
  const token = getToken(); // Get the token from AuthContext
  if (!token) {
    throw new Error('Unauthorized');
  }

  try {
    const response = await axios.post(`${API_URL}/app/dlcp/v0/accept-offer`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400) {
        throw new Error(error.response.data.message || 'Bad Request');
      } else if (error.response?.status === 401) {
        throw new Error('Unauthorized');
      } else if (error.response?.status === 500) {
        throw new Error('Internal Server Error');
      }
    }
    console.error('Error:', error);
    throw error;
  }
};