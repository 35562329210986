import React from 'react';
import { DlcProps } from '../types/dlcFace';

const Dlc: React.FC<DlcProps> = ({ title, dlcs, textColor }) => {
  return (
    <div>
      <h4 className={`text-xl font-semibold ${textColor} mb-4`}>{title}</h4>
      {dlcs.map((dlc, index) => (
        <div
          key={index}
          className='border rounded-lg p-4 shadow-md'
        >
          <p className={`${textColor}`}>
            <strong>DLC ID:</strong> {dlc.dlc_id}
          </p>
          <p className={`${textColor}`}>
            <strong>Temporary Contract ID:</strong> {dlc.tmp_cntr_id}
          </p>
          <p className={`${textColor}`}>
            <strong>Initiator Email:</strong> {dlc.ini_email}
          </p>
          <p className={`${textColor}`}>
            <strong>Initiator Public Key:</strong> {dlc.ini_pubkey}
          </p>
          <p className={`${textColor}`}>
            <strong>Acceptor Email:</strong> {dlc.acc_email}
          </p>
          <p className={`${textColor}`}>
            <strong>Acceptor Public Key:</strong> {dlc.acc_pubkey || 'N/A'}
          </p>
          <p className={`${textColor}`}>
            <strong>Oracle ID:</strong> {dlc.orcl_id}
          </p>
          <p className={`${textColor}`}>
            <strong>Status:</strong> {dlc.status}
          </p>
          <p className={`${textColor}`}>
            <strong>Interest:</strong> {dlc.interest}%
          </p>
          <p className={`${textColor}`}>
            <strong>Payouts:</strong> Win: {dlc.cntr_terms.payouts.win}, Loss:{' '}
            {dlc.cntr_terms.payouts.loss}
          </p>
          <p className={`${textColor}`}>
            <strong>Fee:</strong> {dlc.cntr_terms.fee}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Dlc;
