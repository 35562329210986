import { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import useIdleTimer from './useIdleTimer'; // Import useIdleTimer hook
import { logout } from '../api/auth'; // Import logout function

const useAuthLogic = () => {
  const { saveToken, getToken } = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken();
    const email = localStorage.getItem('userEmail');
    if (token) {
      setIsLoggedIn(true);
      if (email) {
        setUserEmail(email);
      }
    }
  }, [getToken]);

  const handleLogout = useCallback(() => {
    logout();
    setIsLoggedIn(false);
    localStorage.removeItem('userEmail');
    navigate('/loginpage');
  }, [navigate]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('userEmail');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Use the useIdleTimer hook to handle inactivity
  useIdleTimer(handleLogout, 300000); // 5 min

  return {
    isLoggedIn,
    setIsLoggedIn,
    userEmail,
    setUserEmail,
    saveToken,
    getToken,
    handleLogout
  };
};

export default useAuthLogic;