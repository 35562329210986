import axios from 'axios';
import { API_URL } from '../config';
import { OffersResponse } from '../types/offerFace';

// const API_URL = process.env.REACT_APP_STAGING_API_URL;

export const getOffers = async (
  ini_role: string = 'both'
): Promise<OffersResponse> => {
  const response = await axios.get(`${API_URL}/app/dlcp/v0/offers`, {
    params: { ini_role },
    headers: {
      Accept: 'application/json',
    },
  });
  if (response.data && Array.isArray(response.data.payload)) {
    return response.data;
  } else {
    throw new Error('Invalid response format');
  }
};
