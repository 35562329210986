import { useNavigate } from 'react-router-dom';
import useAuthLogic from './useAuthLogic';
import { useEffect, useState } from 'react';

const usePostAuthLogic = () => {
  const { isLoggedIn, handleLogout } = useAuthLogic();
  const navigate = useNavigate();
  const [iniRole, setIniRole] = useState<'lend' | 'borrow' | null>(null);

  useEffect(() => {
    if (isLoggedIn) {
      const role = localStorage.getItem('ini_role') as 'lend' | 'borrow';
      const userEmail = localStorage.getItem('userEmail');
      if (role && userEmail) {
        setIniRole(role);
      }
    }
  }, [isLoggedIn]);

  const handleLoginRedirect = (role: 'lend' | 'borrow') => {
    if (!isLoggedIn) {
      localStorage.setItem('ini_role', role);
      navigate('/loginpage', { state: { from: '/' } });
    } else {
      setIniRole(role);
      navigate('/');
    }
  };

  const handleCancel = () => {
    localStorage.removeItem('ini_role');
    setIniRole(null);
    navigate('/');
  };

  return {
    iniRole,
    isLoggedIn,
    handleLoginRedirect,
    handleLogout,
    handleCancel,
  };
};

export default usePostAuthLogic;
