import React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { removeToken } from '../api/token';
import Price from './Price';

const Header: React.FC<{
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  userEmail: string | null;
}> = ({ isLoggedIn, setIsLoggedIn, userEmail }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeToken();
    localStorage.removeItem('token');
    localStorage.removeItem('ini_role');
    localStorage.removeItem('userEmail');
    setIsLoggedIn(false);
    navigate('/');
    window.location.reload();
  };

  return (
    <header className='flex flex-col md:flex-row justify-between items-center p-4'>
      <div>
        <p className='text-2xl'>
          Download App/
          <a
            href='https://github.com/sziller/_dlc_plaza_wallet'
            target='_blank'
            className='text-teal-500'
            rel='noopener noreferrer'
          >
            Github
          </a>
        </p>
      </div>
      <div className='flex items-center space-x-4'>
        {/* BTC/USD árfolyam */}
        <div className='text-amber-400 text-lg '>
          BTC/USD:
          <strong className='text-amber-400 mx-3'>
            <Price />
          </strong>
        </div>
      </div>
      <div>
        {isLoggedIn ? (
          <>
            <button
              onClick={handleLogout}
              className='uppercase text-teal-500 text-2xl'
            >
              Logout
            </button>
            {userEmail && <p className='text-sm text-gray-400'>{userEmail}</p>}
          </>
        ) : location.pathname === '/loginpage' ? (
          <>
            <Link
              to='/'
              className='uppercase text-teal-500 text-2xl'
            >
              Dashboard
            </Link>
          </>
        ) : (
          <>
            <Link
              to='/loginpage'
              className='uppercase text-teal-500 text-2xl'
            >
              Login
            </Link>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
