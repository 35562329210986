import React, { useState, useEffect } from 'react';
import Dlc from './Dlc';
import { DlcData } from '../types/dlcFace';
import { fetchDlcs } from '../utils/dlc';

const UserDlcsList: React.FC = () => {
  const [dlcs, setDlcs] = useState<DlcData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchDlcs(setDlcs, setLoading, setError); 
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const offerDlcs = dlcs.filter((dlc) => dlc.status === 'offer_p');
  const acceptedDlcs = dlcs.filter((dlc) => dlc.status === 'accepted_p');
  const signedDlcs = dlcs.filter((dlc) => dlc.status === 'signed');

  return (
    <div className='p-4'>
      <h3 className='text-2xl mb-4'>User DLCs</h3>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
        <Dlc
          title='Offer DLCs'
          dlcs={offerDlcs}
          textColor='text-blue-400'
        />
        <Dlc
          title='Accepted DLCs'
          dlcs={acceptedDlcs}
          textColor='text-green-400'
        />
        <Dlc
          title='Signed DLCs'
          dlcs={signedDlcs}
          textColor='text-red-400'
        />
      </div>
    </div>
  );
};

export default UserDlcsList;
