// import exp from 'constants';
import React from 'react';
import { InputProps } from '../../types/common/inputFace';

const Input: React.FC<InputProps> = ({
  type,
  name,
  value,
  placeholder,
  onChange,
  className = 'w-full p-2 border border-gray-300 rounded text-black mb-2',
  required = false,
  autoComplete,
  readOnly,
}) => (
  <input
    type={type}
    name={name}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    className={className}
    required={required}
    autoComplete={autoComplete}
    readOnly={readOnly}
  />
);

export default Input;
