import React from 'react';
import Button from './common/Button';
import usePostAuthLogic from '../hooks/usePostAuthLogic';
import OfferDetails from './OfferDetails';
import useOfferListLogic from '../hooks/useOfferListLogic';
import useOfferLogic from '../hooks/useOfferLogic';
import { OfferData } from '../types/offerFace';

interface AskButtonProps {
  onClick: () => void;
}

const AskButton: React.FC<AskButtonProps> = ({ onClick }) => {
  const { isLoggedIn, handleLoginRedirect } = usePostAuthLogic();
  const { lendOffers, loading, error, refreshOffers } = useOfferListLogic();
  const { handleDetailsClick, selectedOffer, handleCloseDetails } =
    useOfferLogic();

  const handleClick = () => {
    if (!isLoggedIn) {
      handleLoginRedirect('borrow');
    } else {
      localStorage.setItem('ini_role', 'borrow');
      onClick();
    }
  };

  const handleOfferClick = (offer: OfferData) => {
    if (isLoggedIn) {
      handleDetailsClick(offer.dlc_id);
    } else {
      localStorage.setItem('selectedOffer', JSON.stringify(offer));
      handleDetailsClick(offer.dlc_id);
    }
  };

  return (
    <div className='w-1/2 text-center'>
      <Button
        label='Ask'
        onClick={handleClick}
        className='w-full py-8'
      />
      <h1 className='text-4xl font-bold my-5'>BORROW</h1>
      {loading && <p>Loading offers...</p>}
      {error && <p>{error}</p>}
      {lendOffers.length > 0 && (
        <div className='flex justify-center space-x-4'>
          <Button
            label={`${lendOffers[0].interest}%`}
            onClick={() => handleOfferClick(lendOffers[0])}
            className='w-full py-8'
          />
        </div>
      )}
      {selectedOffer && (
        <OfferDetails
          offer={selectedOffer}
          onClose={handleCloseDetails}
          refreshOffers={refreshOffers}
        />
      )}
    </div>
  );
};

export default AskButton;
