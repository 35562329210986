import React from 'react';
import Button from './common/Button'; // Import the Button component
import { OfferProps } from '../types/offerFace'; // Import interfaces
import OfferDetails from './OfferDetails'; // Import the OfferDetails component
import useOfferLogic from '../hooks/useOfferLogic'; // Import useOfferLogic hook
import useOfferListLogic from '../hooks/useOfferListLogic'; // Import useOfferListLogic hook

const Offer: React.FC<OfferProps> = ({ title, offers, textColor }) => {
  const { selectedOffer, handleDetailsClick, handleCloseDetails } = useOfferLogic();
  const { refreshOffers } = useOfferListLogic();

  return (
    <div>
      <h4 className={`text-xl font-semibold ${textColor} mb-4`}>{title}</h4>
      {offers.length > 0 && (
        <div className='grid grid-cols-4 gap-4 mb-2'>
          <div className={`${textColor} font-bold text-lg`}>Interest</div>
          <div className={`${textColor} font-bold text-lg`}>Duration</div>
          <div className={`${textColor} font-bold text-lg`}>EAR</div>
          <div className={`${textColor} font-bold text-lg`}>VIEW</div>
        </div>
      )}
      {offers.map((offer, index) => (
        <div
          key={index}
          className={`grid grid-cols-4 gap-4 border rounded-lg p-4 shadow-sm mb-2 transition-transform transform hover:scale-102 hover:shadow-md focus:scale-102 focus:shadow-md`}
        >
          <div className={`${textColor} text-lg`}>{offer.interest}%</div>
          <div className={`${textColor} text-lg`}>{offer.duration} days</div>
          <div className={`${textColor} text-lg`}>
            {offer.interest_ear.toFixed(2)}%
          </div>
          <div>
            <Button
              label='DETAILS'
              onClick={() => handleDetailsClick(offer.dlc_id)}
            />
          </div>
        </div>
      ))}
      {selectedOffer && (
        <OfferDetails
          offer={selectedOffer}
          onClose={handleCloseDetails}
          refreshOffers={refreshOffers} // Pass the refreshOffers function
        />
      )}
    </div>
  );
};

export default Offer;