import axios from 'axios';
import { API_URL, CLIENT_ID, CLIENT_SECRET } from '../config'; // Import API_URL, CLIENT_ID, and CLIENT_SECRET from config
import { saveToken, getToken, removeToken } from './token'; // Import token functions

// export const API_URL = process.env.REACT_APP_API_URL || '';
// const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';
// const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET || '';

// this function is used to get the authentication token from the API
// it takes an email and password as arguments and returns a token
// it also saves the token to memory using the saveToken function from token.ts
// it has a default timeout of 120000 milliseconds (2 minutes)
// it throws an error if the request fails or times out
// it throws an error if the email or password is invalid
// it throws an error if the response status is 422 (validation error)
// it throws an error if the response status is not handled
// it throws an error if there is no response received
// it throws an error if there is an unexpected error

export const getAuthToken = async (
  email: string,
  password: string,
  timeout: number = 120000
): Promise<string> => {
  try {
    const data = new URLSearchParams({
      grant_type: 'password',
      username: email,
      password,
      scope: '',
      client_id: CLIENT_ID || '',
      client_secret: CLIENT_SECRET || '',
    });

    const response = await axios.post(`${API_URL}/app/auth/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      timeout,
    });

    const token = response.data.access_token;
    saveToken(token);

    if (process.env.NODE_ENV === 'development') {
      console.log('Token successfully retrieved and saved to memory');
    }
    return token;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === 'ECONNABORTED') {
        throw new Error('The request took too long - please try again later.');
      } else if (error.response) {
        switch (error.response.status) {
          case 401:
            throw new Error('Invalid email or password.');
          case 422:
            throw new Error('Validation error: Check your input.');
          default:
            throw new Error('An error occurred. Please try again.');
        }
      } else {
        throw new Error('No response received. Please try again.');
      }
    } else {
      throw new Error(
        error instanceof Error ? error.message : 'Unexpected error occurred.'
      );
    }
  }
};

// this function is used to log out the user by removing the token from memory
// and removing the user email from local storage
export const logout = () => {
  removeToken();
  localStorage.removeItem('userEmail');
  localStorage.removeItem('ini_role');
  localStorage.removeItem('selectedOffer');
  console.log('Token and user email removed');
};

// it calls the removeToken function from token.ts to remove the token
// it removes the user email from local storage
export const isLoggedIn = () => {
  const token = getToken();
  return !!token;
};
