import React from 'react';
// import PriceList from '../components/PriceList';
import OffersList from '../components/OffersList';
import UserDlcsList from '../components/UserDlcsList';
import OfferDetails from '../components/OfferDetails';
import useDashboardLogic from '../hooks/useDashboardLogic';
import FeatureButtons from '../components/FeatureButtons';
import useOfferListLogic from '../hooks/useOfferListLogic';

interface DashboardProps {
  isLoggedIn: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ isLoggedIn }) => {
  const { selectedOffer, setSelectedOffer } = useDashboardLogic(isLoggedIn);
  const { refreshOffers } = useOfferListLogic();

  return (
    <div className='mt-8'>
      <FeatureButtons />
      {isLoggedIn ? (
        <>
          <OffersList />
          <UserDlcsList />
        </>
      ) : (
        <>
          
        </>
      )}

      {/* <PriceList /> */}

      {isLoggedIn && selectedOffer && (
        <OfferDetails
          offer={selectedOffer}
          onClose={() => {
            setSelectedOffer(null);
            localStorage.removeItem('selectedOffer');
          }}
          refreshOffers={refreshOffers} // Pass the refreshOffers function
        />
      )}
    </div>
  );
};

export default Dashboard;