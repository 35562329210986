import React, { useState, useEffect } from 'react';
import { fetchPrice } from '../utils/price';

const Price: React.FC = () => {
  const [price, setPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchPrice(setPrice, setLoading, setError); // Initial fetch
    const interval = setInterval(() => {
      fetchPrice(setPrice, setLoading, setError);
    }, 120000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>{error}</span>;
  }

  return <span>{price !== null ? price.toFixed(2) : 'N/A'}</span>;
};

export default Price;
