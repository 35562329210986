import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import ExplainerVideo from './components/ExplainerVideo';
import FeaturesList from './components/FeaturesList';
import Footer from './components/Footer';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import useAuthLogic from './hooks/useAuthLogic'; // Import useAuthLogic hook

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

const AppContent: React.FC = () => {
  const {
    isLoggedIn,
    setIsLoggedIn,
    userEmail,
    setUserEmail,
    saveToken,
  } = useAuthLogic();

  return (
    <div className='min-h-screen grid grid-rows gap-8 bg-black text-gray-200'>
      <div className='px-6 '>
        <Header
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          userEmail={userEmail}
        />
        <Routes>
          <Route
            path='/loginpage'
            element={
              <Login
                setIsLoggedIn={setIsLoggedIn}
                setUserEmail={setUserEmail}
                saveToken={saveToken}
              />
            }
          />
          <Route
            path='/'
            element={<Dashboard isLoggedIn={isLoggedIn} />}
          />
        </Routes>
      </div>
      <div className='px-6'>
        <div className='flex flex-col md:flex-row md:space-x-8 w-full px-4 items-center justify-center'>
          <div className='w-full md:w-1/2 flex justify-center md:justify-end'>
            <ExplainerVideo />
          </div>
          <div className='w-full md:w-1/2'>
            <FeaturesList />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default App;