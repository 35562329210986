let authToken: string | null = null;

export const saveToken = (token: string) => {
  authToken = token;
};

export const getToken = (): string | null => {
  return authToken;
};

export const removeToken = () => {
  authToken = null;
};
