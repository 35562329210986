import React from 'react';
import Button from './common/Button'; // Import the Button component
import { OfferDetailsProps } from '../types/offerDetailsFace'; // Import the OfferDetailsProps interface
import { useAcceptOffer } from '../hooks/useAcceptOffer'; // Import the useAcceptOffer hook
// import useAuthLogic from '../hooks/useAuthLogic'; // Import the useAuthLogic hook

const OfferDetails: React.FC<OfferDetailsProps> = ({
  offer,
  onClose,
  refreshOffers,
}) => {
  const textColor =
    offer.ini_role === 'lend' ? 'text-purple-400' : 'text-green-400';
  const { acceptOfferWithAuth, loading, error } = useAcceptOffer();
  // const { userEmail } = useAuthLogic(); // Get the user's email

  const handleAccept = async () => {
    console.log('Try to accept offer:', offer);

    try {
      // if (userEmail === offer.ini_email) {
      //   alert('You cannot accept your own offer.');
      //   return;
      // }

      // if (offer.status !== 'offer') {
      //   alert("This offer cannot be accepted because its status is not 'offer'.");
      //   return;
      // }

      const response = await acceptOfferWithAuth({
        dlc_id: offer.dlc_id,
      });
      console.log('Accept offer response:', response);
      alert(response.message);
      onClose();
      refreshOffers(); // Refresh the offer list
    } catch (error: any) {
      if (error.response && error.response.data && error.response.detail) {
        alert(`Error accepting offer: ${error.response.data.detail}`);
      } else if (error instanceof Error && error.message) {
        alert(`Error accepting offer: ${error.message}`);
      } else {
        console.error('Error accepting offer:', error);
      }
    }
  };

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50'>
      <div className='relative bg-black rounded-lg p-8 shadow-lg w-1/2 text-left'>
        <Button
          icon={<span>&times;</span>}
          onClick={onClose}
          className='absolute top-2 right-2 text-white bg-transparent hover:bg-transparent border-none text-3xl hover:bolder focus:bolder hover:text-3xl focus:text-3xl'
        />
        <h2 className={`text-2xl font-bold mb-4 ${textColor}`}>
          Offer Details
        </h2>
        <p className={`${textColor}`}>
          <strong>Product ID:</strong> {offer.product_id || 'N/A'}
        </p>
        <p className={`${textColor}`}>
          <strong>DLC ID:</strong> {offer.dlc_id || 'N/A'}
        </p>
        <p className={`${textColor}`}>
          <strong>Temporary Contract ID:</strong> {offer.tmp_cntr_id || 'N/A'}
        </p>
        <p className={`${textColor}`}>
          <strong>Role:</strong> {offer.ini_role || 'N/A'}
        </p>
        <p className={`${textColor}`}>
          <strong>Interest:</strong> {offer.interest || 'N/A'}%
        </p>
        <p className={`${textColor}`}>
          <strong>Duration:</strong> {offer.duration || 'N/A'} days
        </p>
        <p className={`${textColor}`}>
          <strong>EAR:</strong>{' '}
          {offer.interest_ear ? offer.interest_ear.toFixed(2) : 'N/A'}%
        </p>
        <Button
          label='ACCEPT'
          onClick={handleAccept}
          className='mt-4 bg-transparent border border-gray-300 text-gray-300 hover:bg-gray-300 hover:text-black'
        />
        {loading && <p>Loading...</p>}
        {error && <p className='text-red-500'>{error}</p>}
      </div>
    </div>
  );
};

export default OfferDetails;
