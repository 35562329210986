import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import { LoginProps } from '../types/loginFace';
import { login } from '../utils/login';
import { AuthContext } from '../context/AuthContext';

const Login: React.FC<LoginProps> = ({ setIsLoggedIn, setUserEmail }) => {
  const { saveToken } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/';

  const handleLoginClick = async () => {
    await login(
      email,
      password,
      setIsLoggedIn,
      setUserEmail,
      saveToken,
      navigate,
      setLoading,
      setError
    );

    const selectedOffer = localStorage.getItem('selectedOffer');
    const iniRole = localStorage.getItem('ini_role');
    if (selectedOffer) {
      localStorage.removeItem('selectedOffer');
      navigate(from, { state: { offer: JSON.parse(selectedOffer) } });
    } else if (iniRole) {
      navigate(from, { state: { ini_role: iniRole } });
    } else {
      navigate(from);
    }
  };

  return (
    <div className='flex flex-col space-y-4 my-4'>
      <p className='text-sm text-center mt-8 text-gray-400'>Please log in!</p>
      <form
        onSubmit={(e) => e.preventDefault()}
        className='flex flex-col space-y-2 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mx-auto'
      >
        <Input
          type='email'
          name='email'
          value={email}
          placeholder='Email address'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          required
          autoComplete='email'
        />
        <Input
          type='password'
          name='password'
          value={password}
          placeholder='Password'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          required
          autoComplete='password'
        />
        <div className='flex flex-row justify-between space-x-4'>
          <div className='w-full'>
            <Button
              label='Login'
              onClick={handleLoginClick}
              disabled={loading}
            />
          </div>
        </div>
        {loading && (
          <p className='text-center text-gray-600'>Processing request...</p>
        )}
        {error && <p className='text-center text-red-600'>{error}</p>}
      </form>
    </div>
  );
};

export default Login;