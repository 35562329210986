import { DlcData } from '../types/dlcFace';
import { getUserDlcs } from '../api/userDlcs';

export const fetchDlcs = async (
  setDlcs: (dlcs: DlcData[]) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string | null) => void
) => {
  setLoading(true);
  setError(null);
  try {
    const response = await getUserDlcs();
    setDlcs(response.payload);
  } catch (error) {
    setError('Failed to fetch DLCs');
  } finally {
    setLoading(false);
  }
};
