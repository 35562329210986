import axios from 'axios';
import { getToken } from './token';
import { API_URL } from '../config';
// const API_URL = process.env.REACT_APP_ORACLE_API_URL;

interface Dlc {
  dlc_id: string;
  tmp_cntr_id: string;
  ini_email: string;
  ini_pubkey: string;
  acc_email: string;
  acc_pubkey: string | null;
  orcl_id: string;
  cntr_terms: {
    payouts: {
      win: number;
      loss: number;
    };
    fee: number;
  };
  status: string;
  interest: number;
  ini_signatures: Record<string, any>;
  acc_signatures: Record<string, any>;
  created_at: string;
}

interface UserDlcsResponse {
  timestamp: number;
  payload: Dlc[];
  message: string;
}

export const getUserDlcs = async (
  status_list: string[] = ['offer', 'accepted', 'signed']
): Promise<UserDlcsResponse> => {
  const token = getToken();
  const response = await axios.get(`${API_URL}/app/dlcp/v0/user-dlcs`, {
    params: { status_list },
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.data && Array.isArray(response.data.payload)) {
    return response.data;
  } else {
    throw new Error('Invalid response from server');
  }
};
