import axios from 'axios';
import { getAuthToken } from '../api/auth';

export const login = async (
  email: string,
  password: string,
  setIsLoggedIn: (isLoggedIn: boolean) => void,
  setUserEmail: (email: string | null) => void,
  saveToken: (token: string) => void, // Add saveToken as a parameter
  navigate: (path: string) => void,
  setLoading: (loading: boolean) => void,
  setError: (error: string | null) => void
) => {
  if (!email || !password) {
    alert('Email and password are required');
    return;
  }

  setLoading(true);
  setError(null);
  try {
    const token = await getAuthToken(email, password);
    saveToken(token);
    localStorage.setItem('userEmail', email);
    setIsLoggedIn(true);
    setUserEmail(email);
    navigate('/');
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.code === 'ECONNABORTED') {
        alert('The request took too long - please try again later.');
      } else if (error.response) {
        console.error(
          'API request failed with status:',
          error.response.status
        );
        console.error(
          'Error details:',
          error.response.data.message || 'No message available'
        );
        if (error.response.status === 422) {
          setError('Validation error: Please check your email and password.');
        } else {
          setError('An error occurred. Please try again.');
        }
      } else if (error.request) {
        console.error('No response received during API request.');
        setError('No response received. Please try again.');
      }
    } else {
      console.error(
        'Unexpected error:',
        error instanceof Error ? error.message : error
      );
      setError('An unexpected error occurred. Please try again.');
    }
  } finally {
    setLoading(false);
  }
};