const hostname: string = window.location.hostname;

let API_URL: string = '';
const ORACLE_API_URL: string = process.env.REACT_APP_ORACLE_API_URL || '';
const CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID || '';
const CLIENT_SECRET: string = process.env.REACT_APP_CLIENT_SECRET || '';

if (hostname === 'staging.purabitcoin.com') {
  API_URL = process.env.REACT_APP_STAGING_API_URL || '';
} else if (hostname === 'purabitcoin.com') {
  API_URL = process.env.REACT_APP_API_URL || '';
} else {
  // Default to development environment
  API_URL = process.env.REACT_APP_STAGING_API_URL || '';
}

export { API_URL, ORACLE_API_URL, CLIENT_ID, CLIENT_SECRET };

// import dotenv from 'dotenv';

// dotenv.config();

// export const PURA_API_URL = process.env.REACT_APP_API_URL;
