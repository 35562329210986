import React, { useState } from 'react';
import Button from './common/Button';
import Input from './common/Input';
import usePostAuthLogic from '../hooks/usePostAuthLogic';
import { usePostOffer } from '../hooks/usePostOffer';
import useOfferListLogic from '../hooks/useOfferListLogic';

interface OfferPostFormProps {
  role: 'lend' | 'borrow';
  onClose: () => void; // Add onClose prop to handle closing the form
}

const OfferPostForm: React.FC<OfferPostFormProps> = ({ role, onClose }) => {
  const [interest, setInterest] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  // const { iniRole, isLoggedIn, handleCancel } = usePostAuthLogic();
  const { isLoggedIn, handleCancel } = usePostAuthLogic();
  const { postOfferWithAuth, loading, error } = usePostOffer();
  const { refreshOffers } = useOfferListLogic();

  const handleSubmit = async () => {
    const offerData = {
      product_id: 'LendBorrowBTCUSD',
      tmp_cntr_id: '',
      ini_role: role,
      interest: parseFloat(interest),
    };

    try {
      const response = await postOfferWithAuth(offerData);
      // console.log('Form submitted with interest:', interest);
      // console.log('Submitted offer data:', offerData);
      // console.log('Response:', response);
      setSuccessMessage(response.message);
      refreshOffers(); // Refresh the offer list after successful
      onClose(); // Close the form after successful submission
    } catch (error) {
      // console.error('Failed to submit offer:', error);
      setSuccessMessage(null);
    }
  };

  const handleFormCancel = () => {
    handleCancel();
    onClose(); // Close the form on cancel
  };

  return (
    <div className='w-1/2 mx-auto'>
      {isLoggedIn && (
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <Input
            type='text'
            name='ini_role'
            value={iniRole || ''}
            placeholder='Role'
            readOnly
            onChange={() => {}} // Add a dummy onChange handler
            className='w-full p-2 border border-gray-300 bg-gray-100 text-gray-400 rounded mb-2 readonly'
          /> */}
          <Input
            type='text'
            name='interest'
            value={interest}
            onChange={(e) => setInterest(e.target.value)}
            placeholder='Enter interest'
          />
          <div className='flex space-x-4'>
            <Button
              label={loading ? 'Submitting...' : 'POST'}
              onClick={handleSubmit}
              className='w-full'
              disabled={loading}
            />
            <Button
              label='CANCEL'
              onClick={handleFormCancel}
              className='w-full'
            />
          </div>
          {error && <p className='text-red-500'>{error}</p>}
          {successMessage && <p className='text-green-500'>{successMessage}</p>}
        </form>
      )}
    </div>
  );
};

export default OfferPostForm;
