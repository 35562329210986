import React from 'react';
import Offer from './Offer';
import useOfferListLogic from '../hooks/useOfferListLogic';
import Button from './common/Button';
import { FaSyncAlt } from 'react-icons/fa';

const OffersList: React.FC = () => {
  const { borrowOffers, lendOffers, loading, error, refreshOffers } =
    useOfferListLogic();

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className='p-4'>
      <div className='text-right'>
        <Button
          icon={<FaSyncAlt />}
          onClick={refreshOffers}
        />
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
        <Offer
          title='BORROWS'
          offers={borrowOffers}
          textColor='text-green-400'
        />
        <Offer
          title='LENDS'
          offers={lendOffers}
          textColor='text-purple-400'
        />
      </div>
    </div>
  );
};

export default OffersList;
