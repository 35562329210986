import React, { useEffect } from 'react';

const ExplainerVideo: React.FC = () => {
  useEffect(() => {
    const handleScroll = (event: Event) => {
      // Eseménykezelő logika
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='my-8'>
      <div className='w-72 h-40 bg-gray-500 flex items-center justify-center rounded-lg shadow-lg'>
        <iframe
          width='100%'
          height='100%'
          src='https://www.youtube.com/embed/j256KPBciPE'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          sandbox='allow-same-origin allow-scripts allow-popups allow-presentation'
        ></iframe>
      </div>
    </div>
  );
};

export default ExplainerVideo;
