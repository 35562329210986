import { useState } from 'react';
import { postOffer } from '../api/postOffer';
import useAuthLogic from './useAuthLogic'; // Import useAuthLogic
import { PostOfferParams } from '../types/offerFace';

export const usePostOffer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { getToken } = useAuthLogic(); // Use getToken from useAuthLogic

  const postOfferWithAuth = async (params: PostOfferParams) => {
    setLoading(true);
    setError(null);
    try {
      const token = getToken();
      if (!token) {
        throw new Error('Unauthorized');
      }
      const response = await postOffer(params); // Pass only params
      return response;
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    postOfferWithAuth,
    loading,
    error,
  };
};