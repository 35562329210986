import React from 'react';
import Button from './common/Button';
import usePostAuthLogic from '../hooks/usePostAuthLogic';
import OfferDetails from './OfferDetails';
import useOfferListLogic from '../hooks/useOfferListLogic';
import useOfferLogic from '../hooks/useOfferLogic';
import { OfferData } from '../types/offerFace';

interface BidButtonProps {
  onClick: () => void;
}

const BidButton: React.FC<BidButtonProps> = ({ onClick }) => {
  const { isLoggedIn, handleLoginRedirect } = usePostAuthLogic();
  const { borrowOffers, loading, error, refreshOffers } = useOfferListLogic();
  const { handleDetailsClick, selectedOffer, handleCloseDetails } = useOfferLogic();

  const handleClick = () => {
    if (!isLoggedIn) {
      handleLoginRedirect('lend');
    } else {
      localStorage.setItem('ini_role', 'lend');
      onClick();
    }
  };

  const handleOfferClick = (offer: OfferData) => {
    if (isLoggedIn) {
      handleDetailsClick(offer.dlc_id);
    } else {
      localStorage.setItem('selectedOffer', JSON.stringify(offer));
      handleDetailsClick(offer.dlc_id);
    }
  };

  return (
    <div className='w-1/2 text-center'>
      <Button
        label='Bid'
        onClick={handleClick}
        className='w-full py-8'
      />
      <h1 className='text-4xl font-bold my-5'>LEND</h1>
      {loading && <p>Loading offers...</p>}
      {error && <p>{error}</p>}
      {borrowOffers.length > 0 && (
        <div className='flex justify-center space-x-4'>
          <Button
            label={`${borrowOffers[0].interest}%`}
            onClick={() => handleOfferClick(borrowOffers[0])}
            className='w-full py-8'
          />
        </div>
      )}
      {selectedOffer && (
        <OfferDetails
          offer={selectedOffer}
          onClose={handleCloseDetails}
          refreshOffers={refreshOffers} // Pass the refreshOffers function
        />
      )}
    </div>
  );
};

export default BidButton;